import { AppDispatch, RootState } from '../../../redux/store';
import { connect } from 'react-redux';

import './styles.scss';
import noticeActions from '../../../redux/actions/noticeActions';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import lotteryActions from '../../../redux/actions/lotteryActions';
import {ILatestItem} from '../../../types/index';
import HomeDrawItem from '../../../components/Web/HomeDrawItem';

import {ILotteryList, ILotteryItem} from '../../../types/index';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../../redux/hooks';

import appActions from '../../../redux/actions/appActions';
import { Carousel } from 'antd';

const mapStateToProps = (state:RootState) => ({
  lotteryNews: state.noticeReducers.lotteryNews,
  allLatestResult: state.lotteryReducers.allLatestResult,
  lotteryListData: state.lotteryReducers.lotteryList,
});

const mapDispatchToProps = (dispatch:AppDispatch) => ({
  GetLotteryNews: (top = 10) => dispatch(noticeActions.GetLotteryNews(top)),
  GetAllLatestResult: () => dispatch(lotteryActions.GetAllLatestResult()),
});
interface ICategoryItem {
  label:string,
  value:number
}
interface IProps{
  lotteryListData:ILotteryList, 
  lotteryNews:[],
  allLatestResult:ILatestItem[],
  GetAllLatestResult:()=>void,
}

const categoryList = [5,6];

function Home ({lotteryListData, lotteryNews,allLatestResult,GetAllLatestResult}:IProps){
  const { t } = useTranslation();  
  const [clockTimer, setClockTimer] = useState<string>(new Date().toString())
  const [lotteryData, setLotteryData] = useState(new Map<string,ILotteryItem[]>());
  const [currentSelectedList, setCurrentSelectedList] = useState<ILatestItem[]>();
  const [categoryIndex, setCategoryIndex] = useState<number | undefined>();
  const [categoryArray, setCategoryArray] = useState<ICategoryItem[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();    

  useEffect(()=>{
    GetAllLatestResult();
    const clockId = setInterval(
      (): void => {
        setClockTimer(new Date().toString()
        )
      },
      1000
    );
    return () => {
      clearInterval(clockId);
    };
  },[])

  useEffect(()=>{
    const _timerId = setInterval(
      (): void => {
        GetAllLatestResult()
      },
      5000
    );
    return () => {
      clearInterval(_timerId);
    };
  },[])

  useEffect(()=>{
    if(lotteryListData.lotterys.length > 0 && lotteryListData.categorys.length > 0){
      const temp = (lotteryListData.lotterys as ILotteryItem[]).reduce((a:{[key:string]:ILotteryItem[]},b:ILotteryItem)=>{
        const categoryArr = b.category.split(',');
        for (let index = 0; index < categoryArr.length; index++) {
          const categoryCode = categoryArr[index];
          if(a[categoryCode]){
            a = {...a,[categoryCode]:a[categoryCode].concat(b)}
          }
          else{
            a =  {...a,[categoryCode]:[b].concat([])}
          }  
        }
        return a;
      },{})   
      
      const dataInOrder = new Map<string,ILotteryItem[]>()
      categoryList.forEach(item=> {
        const tempItem = temp[`${item}`];     
        if(tempItem){
          dataInOrder.set(`${item}`,tempItem);
          delete temp[`${item}`];          
        }
      })
      Object.entries(temp).forEach(([key,value])=> dataInOrder.set(key, value) )    
      if(dataInOrder.size > 0){
        const tempCategory:Array<ICategoryItem> = [];
        dataInOrder.forEach((value,key)=>{
          tempCategory.push({value:Number(key),label:lotteryListData.categorys.find(x=>x.value === Number(key))?.label ?? ''})
        })
        setCategoryArray(tempCategory)
        setLotteryData(dataInOrder);
        setCategoryIndex(Number(dataInOrder.keys().next().value))
      }
    }
  },[lotteryListData])

  useEffect(()=>{
    if(allLatestResult && lotteryListData && categoryIndex){
      const tempArr:ILatestItem[] = [];
      const tempData = lotteryData.get(`${categoryIndex}`)  
      if(tempData){
        tempData.forEach(item=>{
          const temp:ILatestItem | undefined = allLatestResult.find(x=>x.code === item.code);
          if(temp){  
            tempArr.push({...temp, category:item.category, name:item.name,openCount:item.openCount ?? 0,template:item.template, group:item.group})    
          }
        })
      }      
      setCurrentSelectedList(tempArr)
    }
  },[allLatestResult,lotteryListData, categoryIndex])  

  const onClickNewsToNavigate = (id = "")=>{
    dispatch(noticeActions.setLotteryNewsId(id))
    navigate('/news') 
  }

  const onLotteryClick = (item: ILotteryItem)=>{
    dispatch(appActions.setTemplateAndCode({template:item.template ?? '',code:item.code}));
    return navigate('/lottery');
  }
  
  return ( 
    <div className='homeWrapper'> 
      <div className='bannerWrapper'>
        <div className='bannerImage'>
          <Carousel autoplay>
            {
              [1,2,3].map((item)=>{
                let lotteryImage;
                try {
                  lotteryImage = require(`../../../assets/banner_web_${item}.png`)
                } catch (error) {
                  lotteryImage = ''
                }
                return <img key={item} src={lotteryImage} className='w-full h-[340px] rounded-[12px]' alt="" srcSet="" />
              })
            }
          </Carousel>
        </div>
        <div className='bannerNews w-[400px] flex flex-col'>
          <div className=' text-[22px] leading-[32px] font-black mb-[10px] px-[30px]'>{t('NEWS_INFO')}</div>
          <div className='flex-1 px-[30px]'>
            {
              lotteryNews.slice(0,5).map((item:{title:string, content:string,createTime:string,id:string}, index)=> {
                if(index === 0){
                  return <div key={`${item.title}-${index}`} className='overflow-hidden mt-[10px] mb-[20px] hover:underline cursor-pointer'
                    onClick={()=>onClickNewsToNavigate(item.id) } >
                    <div className='font-bold  text-[18px] leading-[26px] '>{item.title}</div>
                    <div className='topNewsContent leading-[24px] mt-[10px]'>{item.content}</div>
                  </div>
                }
                return <div  key={`${item.title}-${index}`} className='flex mb-[10px] overflow-hidden hover:underline cursor-pointer' 
                  onClick={()=>onClickNewsToNavigate(item.id) } >
                  <div className='w-[50%] leading-[24px] truncate'>{item.title}</div>
                  <div className='w-[50%] leading-[24px] text-right'>{item.createTime}</div>
                </div>
              })
            }
          </div>
          <div className='px-[30px] text-right cursor-pointer hover:underline' onClick={()=>onClickNewsToNavigate() } >更多</div>
        </div>
        <div className='flex-1 border-l  border-[#dcdcdc] w-[273px]'>
          <div className='text-[22px] leading-[32px] font-black text-center'>热门彩种</div>
          <div className='flex flex-wrap mt-[20px]'>
            {lotteryListData?.lotterys && lotteryListData.lotterys.length > 0 &&
                lotteryListData.lotterys.filter(x=>x.group === 1000).slice(0,9).map((img,index)=>{
                  let lotteryImage;
                  try {
                    lotteryImage = require(`../../../assets/image/${img?.code}.png`)
                  } catch (error) {
                    lotteryImage = ''
                  }
                  return <div 
                    key={`${img.name}-${index}`} 
                    className='w-1/3 flex flex-col justify-center items-center recommendLotteryWrapper' 
                    onClick={()=>onLotteryClick(img)}>
                    <div className='w-[60px] h-[60px]'>
                      <img src={lotteryImage} alt="" srcSet="" />
                    </div>
                    <div className='mb-[13px] leading-[32px] h-[20px] text-center'>{img.name}</div>
                  </div>
                })
            }
          </div>
        </div>
      </div>


      <div className='flex homeCategoryRow items-end'>
        <div className=' text-[24px] font-bold flex-1 '>{t('DRAW_RESULT')}</div>
        <div className='flex justify-end items-end border-b-[#B2B2B2] border-b-[1.5px] '>
          {
            lotteryData.size > 0 && categoryArray.map((obj:ICategoryItem,index)=> 
              <div 
                key={`${index}`}
                onClick={()=>setCategoryIndex(obj.value)} 
                className={`homeCategoryItem ${obj.value === categoryIndex ? 'selected' : ''}`} 
              >
                {obj.label}
              </div> )
          }
        </div>
      </div>
      {
        currentSelectedList && currentSelectedList.map((item,index)=> {
          return<div key={`currentSelected_${index}`} className='homeItemWrapper'>
            <HomeDrawItem item={item} current={clockTimer} target={item.next_time} />
          </div>
        }
        )
      }
    </div>
  );
}


export default connect(mapStateToProps, mapDispatchToProps)(Home);
